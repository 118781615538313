import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import addBtn from '../../assets/images/prelimAdd.svg'
import deleteBtn from '../../assets/images/delete.svg'
import editBtn from '../../assets/images/edit.svg'
import './Notes.scss'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import * as routes from '../../routes/route'
import GuestDetailsHeader from '../../components/GuestDetailsHeader/GuestDetailsHeader'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import openEyeBtn from '../../assets/images/eye-open.svg'
import closeEyeBtn from '../../assets/images/eye-close.svg'
import  {ReactComponent as AddIcon} from '../../assets/images/add.svg'
import  {ReactComponent as LeftArrow} from '../../assets/images/left-arrow.svg'
import  {ReactComponent as RightArrow} from '../../assets/images/right-arrow.svg'
import {deleteConsultationNotes, getConsultationNotes, updateConsultationNotesVisibleStatus} from '../../redux/actions/GuestDataActions/ConsultationNotesActions'
import ChangeVisibilityModal from '../../components/ChangeVisibilityModal/ChangeVisibilityModal'
import arrowDownIcon from '../../assets/images/arrow-down-brown.svg'
import { parseJSON } from '../../utils/helpers/universalFunctions'
import { ViewDietaryNotes } from './Forms/DietaryNotes/ViewDietaryNotes'
import {ReactComponent as FileIcon} from '../../assets/images/file.svg'
import { ViewVitalsCheck } from './Forms/VitalsCheck/ViewVitalsCheck'
import { ViewLabTests } from './Forms/LabTests/ViewLabTests'
import { ViewAyurvedaArrivalConsultation } from './Forms/AyurvedaArrivalConsultation/ViewAyurvedaArrivalConsultation'

const staticNotesType = [
  { title: 'Dietary Notes', component: ViewDietaryNotes },
  { title: 'Post Stay Consultation Notes', component: <></> },
  { title: 'Vitals Check - Arrival', component: ViewVitalsCheck },
  { title: 'Vitals Check - Departure', component: ViewVitalsCheck },
  { title: 'Lab Tests - Arrival', component: ViewLabTests },
  { title: 'Lab Tests - Departure', component: ViewLabTests },
  { title: 'Ayurveda Arrival Consultation', component: ViewAyurvedaArrivalConsultation },
  { title: 'Ayurveda Departure Consultation', component: <></> },
  { title: 'Yoga Arrival Consultation', component: <></> },
  { title: 'Yoga Departure Consultation', component: <></> },
  { title: 'Physio Arrival Consultation', component: <></> },
  { title: 'Physio Departure Consultation', component: <></> },
  { title: 'Tcm Arrival Consultation', component: <></> },
  { title: 'Tcm Departure Consultation', component: <></> },
  { title: 'Post Stay Consultation', component: <></> }
]


function Notes() {
    const location = useLocation();
    const { guestId, userProgramId, type, date} = location.state;
    const [currentDate, setCurrentDate] = useState(date || moment().format('YYYY-MM-DD'))
    const consultationNotesData = useSelector(state=> state.consultationNotesReducer)
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const canWrite = (permissions?.consultation_notes?.permissions || '').includes('write') || (permissions?.dietary_notes?.permissions || '').includes('write')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const handleChangeDate = (num) => {
    //   let newDate = moment(date).add(num, 'day').format('YYYY-MM-DD');
    //   navigate(routes.VIEW_CONSULTATION_NOTES_ENDPOINT, { 
    //     replace: true, 
    //     state: {guestId, userProgramId, type, date: newDate}
    //   })
    // }

    const handleAdd = ()=>{
      navigate(routes.ADD_CONSULTATION_NOTES_ENDPOINT, { 
        state: {guestId, userProgramId, type, date: currentDate}
      })
    }

    useEffect(()=>{
        if(guestId && userProgramId){
            dispatch(getConsultationNotes({guestId, userProgramId, date: ''}))
        }
    },[])


  return (
        <>
            <div className="consultation-notes-container">
                <div className="container h-100">
                    <div className="consultation-notes-heading">
                        Consultant Recommendations
                    </div>

                    {   loading && (guestId !== guestDetails?.user_id || !guestDetails) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                        <div className="pt-4 pb-5">
                            <div className="consultation-notes-content">
                                <GuestDetailsHeader guestId={guestId} />
                                {/* <div className='d-flex note-header items-center'>
                                  <div className="prev-next-btn d-flex justify-content-between items-center mx-2 flex-grow-1">
                                    <button type='button'  className='next-btn' onClick={()=>{handleChangeDate(-1)}}><LeftArrow/>  Prev</button>
                                    <div className='time my-auto'>{moment(new Date(currentDate)).format('MMM DD, YYYY')}</div>
                                    <button type='button'  className='prev-btn' disabled={currentDate === moment().format('YYYY-MM-DD')} onClick={()=>{handleChangeDate(1)}}>Next <RightArrow/></button>
                                  </div>
                                </div> */}
                                <div className='d-flex note-header justify-content-end'>
                                  {
                                    !!consultationNotesData?.consultationNotesData?.length && canWrite &&
                                    <button className="add-btn flex-shrink-1 mx-2 d-flex items-center justify-content-between" type='submit' 
                                      onClick={handleAdd}
                                    >
                                      <AddIcon />
                                      <span>Add Note</span>
                                    </button>
                                  }
                                </div>
                                {
                                    consultationNotesData?.loading ? 
                                    <div className="loader">
                                        <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                                    </div>
                                    :
                                    <>
                                        {
                                            (!consultationNotesData?.consultationNotesData?.length) ?
                                            
                                            <div className="guest-add">
                                                {
                                                    canWrite
                                                    ?
                                                    <div data-testid="add-btn" className="add" onClick={handleAdd}>
                                                        <img src={addBtn} width={40} alt="" />
                                                        <div className='text'>Add Note</div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            <div className='all-notes'>
                                              {/* {
                                                consultationNotesData?.consultationNotesData?.map((item, index)=>{
                                                  return (
                                                    <React.Fragment key={item?.note_id}>
                                                      {
                                                        item.notes_type==='Dietary Notes' && permissions?.dietary_notes?.permissions === 'hide' ?
                                                        null:
                                                        <>
                                                          <SingleNote 
                                                            note={item}
                                                            content={item.notes_type==='Dietary Notes' ? JSON.parse(item.notes_detail) : item.notes_detail}
                                                            guestId={guestId}
                                                            userProgramId={userProgramId}
                                                            currentDate={currentDate}
                                                            canWrite={canWrite}
                                                          />
                                                          {consultationNotesData?.consultationNotesData?.length !== index+1 && <div className="hr"></div>}
                                                        </>
                                                      }
                                                    </React.Fragment>
                                                  )
                                                })
                                              } */}
                                              {
                                                consultationNotesData?.consultationNotesData?.map((item, index)=>{
                                                  return (
                                                    <React.Fragment key={item?.note_id}>
                                                      {
                                                        item.notes_type==='Dietary Notes' && permissions?.dietary_notes?.permissions === 'hide' ?
                                                        null:
                                                        <>
                                                          <GroupedNotes 
                                                            data={item}
                                                            guestId={guestId}
                                                            userProgramId={userProgramId}
                                                          />
                                                        </>
                                                      }
                                                    </React.Fragment>
                                                  )
                                                })
                                              } 
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }                
                </div>
            </div>
        </>
  )
}

export default Notes


function GroupedNotes({data, guestId, userProgramId}) {
  const [toggle, setToggle] = useState(true);
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;


  return (
    <div className='px-3'>
      <Accordion 
        expanded={toggle}
        onChange = {(e,expanded) => {
          if(expanded){
              setToggle(true)
          }else{
              setToggle(false)
          }
      }}>
          <AccordionSummary
            expandIcon={<img src={arrowDownIcon}/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
          <div className='accordian-title ms-3'>
              {data.notice_type}
          </div>
          </AccordionSummary>
          <AccordionDetails>
          {
            data?.list?.map((item, index)=>{
              return (
                <React.Fragment key={item?.note_id}>
                  {
                    item.notes_type==='Dietary Notes' && permissions?.dietary_notes?.permissions === 'hide' ?
                    null:
                    <>
                      <SingleNote 
                        note={item}
                        guestId={guestId}
                        userProgramId={userProgramId}
                      />
                      {data?.list?.length !== index+1 && <div className="hr"></div>}
                    </>
                  }
                </React.Fragment>
              )
            })
          }
          </AccordionDetails>
      </Accordion>
    </div>
  )
}

function SingleNote({note,  userProgramId, guestId}) {
  const [toggle, setToggle] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visibilityModal, setVisibilityModal] =useState(false)
  const [eye, setEye] = useState(note?.is_guest_visible)
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const canWriteNote = (permissions?.consultation_notes?.permissions || '').includes('write')
  const canWriteDietNote = (permissions?.dietary_notes?.permissions || '').includes('write')
  const canWrite = (note.notes_type === "Dietary Notes" ? canWriteDietNote : canWriteNote) && note?.user_id === userData?.user_id

  const deleteNote  = (id, noteType, docs) =>{
    dispatch(deleteConsultationNotes({noteId: id, noteType, docs}))
    setDeleteModal(false)
  }
  
  const handleEye = ()=>{
    setEye(!eye); 
    dispatch(updateConsultationNotesVisibleStatus({noteId: note?.note_id, isVisible: !eye}))
    setVisibilityModal(false)
}

  return (
    
    <div className=' ms-5'>
      <Accordion onChange = {(e,expanded) => {
          if(expanded){
              setToggle(true)
          }else{
              setToggle(false)
          }
      }}
      style={{border: 0}}
      >
          <AccordionSummary
            expandIcon={<img src={arrowDownIcon}/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{width: '100%', margin: '0 !important', borderBottom: 0}}

            >
          <div className='accordian-title ms-3 w-100'>
            <div className="d-flex justify-content-between align-items.center">
              <div className='note-title'>
                <div className="note-footer d-flex justify-content-between align-items-center">
                  <div className='added-by'>Added By:  <span> {note?.first_name+ ' ' + note?.last_name}</span></div>
                  <div className='text-muted' style={{width: '190px'}}>Updated On: {moment(note?.f_updation_datetime).format('MMM DD, YYYY')}</div>
              </div>
              </div>
              
                <div className="icons">
                {
                  canWrite ?
                  <>
                    <>
                      <div className="vhr"></div>
                      <img src={eye ? openEyeBtn : closeEyeBtn} alt="eye-btn" className="" onClick={()=>setVisibilityModal(true)}/>
                      <img src={editBtn} alt="edit-btn" className="" onClick={()=>navigate(routes.EDIT_CONSULTATION_NOTES_ENDPOINT, {state: { noteId: note?.note_id, guestId, userProgramId}})}/>
                      <img src={deleteBtn} alt="del-btn" className="" onClick={()=>setDeleteModal(true)}/>
                    </>
                  </>
                  :
                  <>
                    {
                      !!note?.is_guest_visible  &&
                      <>
                        <div className="vhr"></div>
                        <div className='view-permission'>Visible to Guest</div>
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="ms-4 ps-1">
              {
                (()=>{
                  let isJSONNote = staticNotesType.find(item => item.title === note.notes_type)
                  return (
                    <>
                      {
                        isJSONNote?
                        React.createElement(isJSONNote.component, {content: parseJSON(note?.notes_detail)})
                        :
                        <div className='quilDiv'>
                          <ReactQuill
                              className='quillMainDiv'
                              theme="snow"
                              value={note?.notes_detail}
                              readOnly={true}
                          />
                      </div>
                      }
                    </>
                  )
                })()
              }
              <div className='d-flex mb-3 '>
                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Uploaded Files:</div>
                <div className="text-break">
                  {
                    note.notes_doc.length>0 ? note.notes_doc?.map((item, index)=>{
                      return(
                        <Link className='d-block text-decoration-none mb-2 fw-semi-bold' target="_blank"  to={note.notes_url[index]} key={index}>
                          <FileIcon/> &nbsp;
                          {item?.split('-').slice(1).join('-')}
                        </Link>
                      )
                    })

                    :
                    '-'
                  }
              </div>
              </div>
            </div>
          </AccordionDetails>
      </Accordion>
      
      
      
      <ChangeVisibilityModal
        warnModal={visibilityModal} 
        setWarnModal={setVisibilityModal} 
        onYes={handleEye}
        hide={eye}
        />
      <DeleteModal 
          deleteModal={deleteModal} 
          setDeleteModal={setDeleteModal}
          name="this note"
          onDelete = {()=>deleteNote(note?.note_id, note?.notes_type, note.notes_doc) }
      />
    </div>
  )
}