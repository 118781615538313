import moment from 'moment'
import { LabTestsField } from '../NoteFormsConstants'
  

export const ViewLabTests = ({content})=>{
    return (
        <div className="mt-3">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Conducted By:</div>
                    <div className="text-break white-space">{content.conducted_by || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className='mb-3 border p-2 rounded'>
                {
                    LabTestsField?.map((item, index)=>(
                        <div className='d-flex mb-3' key={index}>
                            <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                            <div className="text-break white-space">{content.data[item.key].value ? `${content.data[item.key].value}` : '-'}</div>
                        </div>
                        ))
                }
            </div>
        </div>
    )
}
