import { AddEditAyurvedaArrivalConsultation } from "./AyurvedaArrivalConsultation/AddEditAyurvedaArrivalConsultation/AddEditAyurvedaArrivalConsultation";
import { AddEditDietaryNotes } from "./DietaryNotes/AddEditDietaryNotes/AddEditDietaryNotes";
import { AddEditLabTests } from "./LabTests/AddEditLabTests/AddEditLabTests";
import { AddEditVitalsCheck } from "./VitalsCheck/AddEditVitalsCheck/AddEditVitalsCheck";

export const DEPARTURE='departure';
export const ADD='add';
export const EDIT='edit';

export const DietrayNoteField = [
    {label: "Detox Diet Days", key: 'detox_diet_days', type: 'input'},
    {label: "Liquid Diet Days", key: 'liquid_diet_days', type: 'input'},
    {label: "Recommended Diet", key: 'recommended_diet'},
    {label: "Allergies", key: 'allergies'},
    {label: "General Diet Information", key: 'general_diet_information'},
    {label: "General Beverage Information", key: 'general_beverage_information'},
  ]

export const DietrayNoteJSON = {
  "detox_diet_days": "",
  "liquid_diet_days": "",
  "recommended_diet": "",
  "allergies": "",
  "general_diet_information": "",
  "general_beverage_information": ""
}

export const VitalsCheckField = [
  {label: "Blood Pressure", key: 'blood_pressure', unit: 'mm Hg', machine: "BP", type: 'text'},
  {label: "Pulmonary Function", key: 'pulmonary_function', unit: 'mL', machine: "SPIROMETER", type: 'numeric'},
  {label: "Weight", key: 'weight', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Skeletal Muscle Mass (SMM)", key: 'smm', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Body Fat", key: 'body_fat', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Percentage Body Fat (PBF)", key: 'pbf', unit: '%', machine: "BMI", type: 'numeric'},
  {label: "Body Mass Index", key: 'bmi', unit: 'kg/m2', machine: "BMI", type: 'numeric'},
  {label: "Oxygen Saturation", key: 'oxygen_saturation', unit: 'spO2', machine: "OXYMETER", type: 'numeric'},
  {label: "Heart Rate (Resting)", key: 'heart_rate', unit: 'BPM', machine: "OXYMETER", type: 'numeric'},
]

export const VitalsCheckJSON = {
  "data": {
    "blood_pressure": { value: "", unit: "mm Hg" },
    "pulmonary_function": { value: "", unit: "mL" },
    "weight": { value: "", unit: "Kg" },
    "smm": { value: "", unit: "Kg" },
    "body_fat": { value: "", unit: "Kg" },
    "pbf": { value: "", unit: "%" },
    "bmi": { value: "", unit: "kg/m2" },
    "oxygen_saturation": { value: "", unit: "spO2" },
    "heart_rate": { value: "", unit: "BPM" }
  },
  "conducted_by": "",
  "date": ""
}

export const LabTestsField = [
  {label: "Lipid Profile", key: 'lipid_profile'},
  {label: "Thyroid Profile", key: 'thyroid_profile'},
  {label: "Liver Function Test", key: 'liver_function_test'},
  {label: "Kidney Function Test", key: 'kidney_function_test'},
  {label: "Hba1c", key: 'hba1c'},
  {label: "CBC", key: 'cbc'},
  {label: "Vitamin B12", key: 'vitamin_b12'},
  {label: "Vitamin D", key: 'vitamin_d'},
  {label: "GGT", key: 'ggt'},
  {label: "Phosphorus", key: 'phosphorus'},
  {label: "Hepatitis B", key: 'hepatitis_b'},
  {label: "Iron Test", key: 'iron_test'},
  {label: "Urine Test", key: 'urine_test'},
  {label: "ESR", key: 'esr'},
];

export const LabTestsJSON = {
  "data": {
    "lipid_profile": { value: ""},
    "thyroid_profile": { value: ""},
    "liver_function_test": { value: ""},
    "kidney_function_test": { value: ""},
    "hba1c": { value: ""},
    "cbc": { value: ""},
    "vitamin_b12": { value: ""},
    "vitamin_d": { value: ""},
    "ggt": { value: ""},
    "phosphorus": { value: ""},
    "hepatitis_b": { value: ""},
    "iron_test": { value: ""},
    "urine_test": { value: ""},
    "esr": { value: ""},
  },
  "conducted_by": "",
  "date": ""
};

export const AyurvedaArrivalConsultationField = {
  "currentDietaryPattern": {
    "intake": [
      { label: "Breakfast", key: "breakfast" },
      { label: "Afternoon Snack", key: "afternoon_snack" },
      { label: "Lunch", key: "lunch" },
      { label: "Evening Snack", key: "evening_snack" },
      { label: "Dinner", key: "dinner" },
      { label: "Night Snack", key: "night_snack" }
    ],
    "additionalInfo": [
      { label: "Plain Water", key: "pain_water" },
      { label: "Dairy Products", key: "dairy_products" },
      { label: "Coffee/Tea", key: "coffee_tea" },
      { label: "Cold Drink/Soda", key: "cold_drink_soda" },
      { label: "Herbal Tea/Juice", key: "herbal_tea_juice" },
      { label: "Alcohol", key: "alcohal" }
    ]
  },
  "healthAssessment": [
    { label: "AGNI (DIGESTION)", key: "agni" },
    { label: "KOSHTHA (GUT BEHAVIOUR)", key: "koshtha" },
    { label: "MALA (EXCRETORY FUNCTION)", key: "mala" },
    { label: "AMA (TOXIN LEVEL)", key: "ama" },
    { label: "MANAS (MENTAL HEALTH)", key: "manas" },
    { label: "NIDRA (SLEEP)", key: "nidra" },
    { label: "VYAYAMA (EXERCISE)  ", key: "vyayama" },
  ],
  "ashthavidhaPariksha": [
    { label: "NADI (examination of pulse)", key: "nadi" },
    { label: "JIHWA (examination of tongue)", key: "jihwa" },
    { label: "MALA (examination of stool)", key: "mala" },
    { label: "MUTRA (examination of urine)", key: "mutra" },
    { label: "SHABDHA (auscultation)", key: "shabdha" },
    { label: "SPARSHA (palpation)", key: "sparsha" },
    { label: "DRIK (inspection)", key: "drik" },
    { label: "AKRITI (examination of whole body)", key: "akriti" }
  ],
  "objectives": [
    { label: "Treatment protocol", key: "treatment_protocol" },
    { label: "Follow during stay", key: "follow_during_stay" },
    { label: "Avoid during stay", key: "avoid_during_stay" },
    { label: "Medicines/ Tests prescribed", key: "medicines" },
  ]
};


export const AyurvedaArrivalConsultationJSON = {
  "data": {
    "current_dietary_pattern": {
      "intake" : {
        "breakfast": "",
        "afternoon_snack": "",
        "lunch": "",
        "evening_snack": "",
        "dinner": "",
        "night_snack": ""
      },
      "additional_info": {
        "plain_water": "",
        "dairy_products": "",
        "coffee_tea": "",
        "cold_drink_soda": "",
        "herbal_tea_juice": "",
        "alcohal": ""
      }
    },
    "ayurvedic_health_assessment": {
      "agni": "",
      "koshtha": "",
      "mala": "",
      "ama": "",
      "manas": "",
      "nidra": "",
      "vyayama": "",
    },
    "systemic_assessment": {
      "respiratory_system": {
        "lung_field": "",
        "auscultation": ""
      },
      "cardiovascular_system": {
        "pulse_rate": {value: "", unit: "bpm"},
        "blood_pressure": {value: "", unit: "mm Hg"},
        "spo2": {value: "", unit: "%"},
        "remarks": ""
      },
      "musculoskeletal_system": "",
      "skin": "",
    },
    "ashthavidha_pariksha": {
      "nadi": "",
      "jihwa": "",
      "mala": "",
      "mutra": "",
      "shabdha": "",
      "sparsha": "",
      "drik": "",
      "akriti": ""
    },
    "lab_reports": "",
    "summary_assessment_treatment_plan": {
      "dosha_prakriti": "",
      "dosha_vikruiti": "",
      "objectives": [
        {
          "treatment_protocol": "",
          "follow_during_stay": "",
          "avoid_during_stay":"",
          "medicines": ""
        }
      ],
    }
  },
  "conducted_by": "",
  "date": ""
}

export const staticNotesType = [
  { title: 'Dietary Notes', component: AddEditDietaryNotes, json: DietrayNoteJSON},
  { title: 'Post Stay Consultation Notes', component: <></> },
  { title: 'Vitals Check - Arrival', component: AddEditVitalsCheck, json: VitalsCheckJSON},
  { title: 'Vitals Check - Departure', component: AddEditVitalsCheck, json: VitalsCheckJSON, type: DEPARTURE },
  { title: 'Lab Tests - Arrival', component: AddEditLabTests, json: LabTestsJSON },
  { title: 'Lab Tests - Departure', component: AddEditLabTests, json: LabTestsJSON , type: DEPARTURE },
  { title: 'Ayurveda Arrival Consultation', component: AddEditAyurvedaArrivalConsultation, json: AyurvedaArrivalConsultationJSON },
  { title: 'Ayurveda Departure Consultation', component: <></> },
  { title: 'Yoga Arrival Consultation', component: <></> },
  { title: 'Yoga Departure Consultation', component: <></> },
  { title: 'Physio Arrival Consultation', component: <></> },
  { title: 'Physio Departure Consultation', component: <></> },
  { title: 'Tcm Arrival Consultation', component: <></> },
  { title: 'Tcm Departure Consultation', component: <></> },
  { title: 'Post Stay Consultation', component: <></> }
]


export const fileTypes = [
  'application/msword',     // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/pdf',         // .pdf
  'text/plain',              // .txt
  'image/jpeg',              // .jpg, .jpeg
  'image/png'                // .png
]